import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// @ts-ignore
import pagarme from 'pagarme';

import axiosInstance from '../configs/axios';

const pagarmeKey = process.env.NEXT_PUBLIC_PAGARME_ENCRYPT_KEY ?? '';

interface PagarmeValidateProps {
  cardHolder: string;
  cardNumber: string;
  expireDate: string;
  cvcCode: string;
}

interface CheckoutPagarme {
  gateway_id: number;
  plan_id: number;
  installments: number;
  currency_id: number;
  coupon?: string;
  ref?: string;
  cc_token: string;
  card: {
    card_holder_name: string;
    card_number: string;
    card_expiration_date: string;
    card_cvv: string;
  };
  user_doc: string;
  user_phone: string;
  credit_card: string;
  payment_method: string;
  boleto: string;
}

export const validateCard = createAsyncThunk('appCheckout/checkoutPagarme', async (data: PagarmeValidateProps) => {
  const card = {
    card_holder_name: data.cardHolder,
    card_number: data.cardNumber,
    card_expiration_date: data.expireDate,
    card_cvv: data.cvcCode,
  };
  const response = pagarme.validate({ card });

  if (!response.card.card_holder_name || !response.card.card_number || !response.card.card_cvv || !response.card.card_expiration_date) {
    return { success: false, data: 'Invalid card data' };
  }

  // TODO: isso precisa rodar na api do next pra nao mostrar as chaves
  return (
    pagarme.client
      .connect({ encryption_key: pagarmeKey })
      // @ts-ignore
      .then((client) => client.security.encrypt(card))
      // @ts-ignore
      .then((card_hash) => {
        return {
          success: true,
          data: 'Credit card is valid',
          hash: card_hash,
        };
      })
      // @ts-ignore
      .catch((error) => {
        return { success: false, data: error.response.data };
      })
  );
});

export const checkoutPagarme = createAsyncThunk('appCheckoutPagarme/checkoutPagarme', async (data: CheckoutPagarme) => {
  try {
    const response = await axiosInstance.post('/checkout', data);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { error: err?.response?.data };
  }
});

export const checkoutPagarmeTrial = createAsyncThunk('appCheckoutPagarme/checkoutPagarmeTrial', async (data: CheckoutPagarme) => {
  try {
    const response = await axiosInstance.post('/checkout/trial', data);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { error: err?.response?.data };
  }
});

export const appCheckoutPagarmeSlice = createSlice({
  name: 'appCheckout',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appCheckoutPagarmeSlice.reducer;
