import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

interface CancelSubscriptionProps {
  data: BodyBackEndSubscriptionProps;
}

interface BodyBackEndSubscriptionProps {
  subscription_id: number;
  reason: string;
  bug: string;
  nopingNegativeFeedback: string;
}

export const cancelSubscription = createAsyncThunk('appSubscription/cancelSubscription', async ({ data }: CancelSubscriptionProps) => {
  try {
    const response = await axiosInstance.post(`subscriptions/cancel/${data.subscription_id}`, data);
    return response;
  } catch (error) {
    return {
      success: false,
      error: (error as any)?.response?.data,
      status: (error as any)?.response?.status,
    };
  }
});

export const getAllSubscriptionByUser = createAsyncThunk('appSubscription/getAllSubscriptionByUser', async () => {
  try {
    const response = await axiosInstance.get('/user/subscriptions');
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: (error as any)?.response?.data,
      status: (error as any)?.response?.status,
    };
  }
});

export const appSubscription = createSlice({
  name: 'appSubscription',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appSubscription.reducer;
