import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

interface BodyBackEndCouponProps {
  code: string;
  recaptcha: string;
}

export const validateCoupon = createAsyncThunk('appValidateCoupon/validateCoupon', async ({ code, recaptcha }: BodyBackEndCouponProps) => {
  try {
    const body = { code, recaptcha };
    const response = await axiosInstance.post(`user/redeem-coupon`, body);
    return response.data;
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const appValidateCouponSlice = createSlice({
  name: 'appValidateCoupon',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appValidateCouponSlice.reducer;
