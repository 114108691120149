import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { ReactNode } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import authConfig from 'src/configs/auth';
import axiosInstance from 'src/configs/axios';

import type { AuthValuesType, ConfirmationCallbackType, CreateUserParams, CreateUserTrialParams, ErrCallbackType, LoginParams, UserDataType } from './types';

const encoder = new TextEncoder();
const decoder = new TextDecoder();

async function getKey(password: string | undefined) {
  const baseKey = await crypto.subtle.importKey('raw', encoder.encode(password), { name: 'PBKDF2' }, false, ['deriveBits', 'deriveKey']);
  return crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: encoder.encode('pick-a-salt'),
      iterations: 100000,
      hash: 'SHA-256',
    },
    baseKey,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );
}

async function encryptData(data: UserDataType, password: string) {
  const key = await getKey(password);
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const alg = { name: 'AES-GCM', iv };
  const encrypted = await crypto.subtle.encrypt(alg, key, encoder.encode(JSON.stringify(data)));
  return {
    encrypted: Array.from(new Uint8Array(encrypted)),
    iv: Array.from(iv),
  };
}

async function decryptData(encryptedData: Iterable<number>, iv: Iterable<number>, password: string) {
  const key = await getKey(password);
  const alg = { name: 'AES-GCM', iv: new Uint8Array(iv) };
  const decrypted = await crypto.subtle.decrypt(alg, key, new Uint8Array(encryptedData));
  return JSON.parse(decoder.decode(decrypted));
}

const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  create: () => Promise.resolve(),
  me: () => Promise.resolve(),
  createTrial: () => Promise.resolve(),
};

const AuthContext = createContext<AuthValuesType>(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const router = useRouter();
  const { t } = useTranslation('common');

  const encryptionKey = 'bcE8BaWNUKBxDUlOZQdW7KdGNcg6lRax4ONw26geqfQ=';

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('iv');
    setUser(null);
    if (router.pathname.includes('/dashboard/')) {
      router.replace('/');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const encryptedUser = localStorage.getItem('user');
      const iv = localStorage.getItem('iv');
      const authCookie = localStorage.getItem('npCookiesAccepted');

      if (!authCookie) {
        handleLogout();
        return;
      }

      if (encryptedUser && iv && encryptionKey) {
        try {
          const decryptedUser = await decryptData(JSON.parse(encryptedUser), JSON.parse(iv), encryptionKey);
          setUser(decryptedUser);
        } catch (error) {
          localStorage.removeItem('user');
          localStorage.removeItem('iv');
        }
      }
    };

    fetchData();
  }, [encryptionKey]);

  useEffect(() => {
    const saveData = async () => {
      if (user && encryptionKey) {
        const { encrypted, iv } = await encryptData(user, encryptionKey);
        localStorage.setItem('user', JSON.stringify(encrypted));
        localStorage.setItem('iv', JSON.stringify(iv));
      } else {
        localStorage.removeItem('user');
        localStorage.removeItem('iv');
      }
    };

    saveData();
  }, [user, encryptionKey]);

  const initAuth = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(authConfig.meEndpoint);
      if (response.data && response.data.data && response.data.data.user) {
        setUser({
          id: response.data.data.user.id,
          name: response.data.data.user.nome,
          email: response.data.data.user.usuario,
          vipTime: response.data.data.user.vip_time,
          trialSubDate: response.data.data.user.trial_sub_date,
          daysPlan: response.data.data.user?.days,
          customTrial: response.data.data.custom_trial,
          cellphone: response.data.data.user.telefone,
          country: response.data.data.user.country,
          favGame: response.data.data.user.fav_game,
          addressNumber: response.data.data.user.address_number,
          address: response.data.data.user.address_address,
          addressState: response.data.data.user.address_state,
          addressCep: response.data.data.user.address_cep,
          addressZip: response.data.data.user.address_zip,
        });
        if (router.pathname === '/pricing' || router.pathname.startsWith('/trial') || router.pathname === '/boost-fps') {
          return;
        }
        if (router.pathname === '/affiliates') {
          router.push('/dashboard/affiliate');
        } else {
          router.push('/dashboard/overview');
        }
      } else {
        throw new Error('User data is not available');
      }
    } catch (error) {
      setUser(null);
      if (error instanceof Error) {
        toast.error(error.message || 'Failed to authenticate', {
          position: 'top-center',
        });
      } else {
        toast.error('Failed to authenticate', {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const me = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(authConfig.meEndpoint);
      if (response.data && response.data.data && response.data.data.user) {
        setUser({
          id: response.data.data.user.id,
          name: response.data.data.user.nome,
          email: response.data.data.user.usuario,
          vipTime: response.data.data.user.vip_time,
          trialSubDate: response.data.data.user.trial_sub_date,
          daysPlan: response.data.data.user?.days,
          customTrial: response.data.data.custom_trial,
          cellphone: response.data.data.user.telefone,
          country: response.data.data.user.country,
          favGame: response.data.data.user.fav_game,
          addressNumber: response.data.data.user.address_number,
          address: response.data.data.user.address_address,
          addressState: response.data.data.user.address_state,
          addressCep: response.data.data.user.address_cep,
          addressZip: response.data.data.user.address_zip,
        });
      }
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (params: LoginParams) => {
    setLoading(true);
    axiosInstance
      .post(authConfig.loginEndpoint, params)
      .then((response) => {
        if (response.data && response.data.success) {
          initAuth();
        } else {
          const errors = response.data.errors || {};
          const errorMessages = Object.keys(errors)
            .map((key) => errors[key].join(', '))
            .join('\n');
          toast.error(errorMessages || 'Login failed due to server error.', {
            position: 'top-center',
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || 'Network error during login.', {
          position: 'top-center',
        });
        setLoading(false);
      });
  };

  const handleCreate = (params: CreateUserParams, errorCallback?: ErrCallbackType, successCallback?: ConfirmationCallbackType) => {
    axiosInstance
      .post(authConfig.registerEndpoint, params)
      .then((response) => {
        if (response.data.success) {
          toast.success(t('login.mensage_user_created_successfully'));
          if (successCallback) successCallback();
        } else {
          throw new Error('User creation failed');
        }
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const handleCreateTrial = (params: CreateUserTrialParams, errorCallback?: ErrCallbackType, successCallback?: ConfirmationCallbackType) => {
    axiosInstance
      .post(authConfig.registerTrialEndpoint, params)
      .then((response) => {
        if (response.data.success) {
          toast.success(t('login.mensage_user_created_successfully'));
          if (successCallback) successCallback();
        } else {
          throw new Error('User creation failed');
        }
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const values = useMemo(
    () => ({
      user,
      loading,
      setUser,
      setLoading,
      login: handleLogin,
      logout: handleLogout,
      create: handleCreate,
      createTrial: handleCreateTrial,
      me,
    }),
    [user, loading, handleLogin, handleLogout, handleCreate, me, handleCreateTrial]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
