import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

interface CoinPaymentsValidateProps {
  gateway_id: number;
  plan_id: number;
  currency_id: number;
  coupon?: string;
  ref?: string;
  installments: number;
}

export const checkoutCoinPayments = createAsyncThunk('appCheckoutCoinPayments/checkoutCoinPayments', async (data: CoinPaymentsValidateProps) => {
  try {
    const response = await axiosInstance.post('/checkout', data);

    return { status: response.status, res: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const appCheckoutCoinPaymentsSlice = createSlice({
  name: 'appCheckoutCoinPayments',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appCheckoutCoinPaymentsSlice.reducer;
