import '@/styles/global.css';

import { GoogleAnalytics, GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import type { AppProps } from 'next/app';
import { Bai_Jamjuree } from 'next/font/google';
import { appWithTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from '@/context/auth-context';
import { store } from '@/store';

const baiJamjuree = Bai_Jamjuree({
  subsets: ['latin'],
  weight: ['200', '300', '400', '500', '600', '700'],
  display: 'swap',
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    const props = {
      page_title: pageProps.slug || null,
    };
    sendGTMEvent({ event: 'page_view', ...props });
  }, []);

  return (
    <Provider store={store}>
      <AuthProvider>
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER!} />
        <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS!} />
        <div className={baiJamjuree.className}>
          <Component {...pageProps} />
        </div>
        <ToastContainer position='top-right' autoClose={2000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='dark' />
      </AuthProvider>
    </Provider>
  );
};

export default appWithTranslation(MyApp);
