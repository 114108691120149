import axios from 'axios';

const baseUrlApi = process.env.NEXT_PUBLIC_API_URL ?? '';

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseUrlApi,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
