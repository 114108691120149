import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

import auth from './auth';
import coinPayments from './coin-payments';
import coupon from './coupon';
import faq from './faq';
import game from './game';
import genericCheckout from './generic-checkout';
import mercadoPago from './mercado-pago';
import pagarme from './pagarme';
import paypal from './paypal';
import stripe from './stripe';
import subscription from './subscription';
import ticket from './ticket';
import trial from './trial';

export const store = configureStore({
  reducer: {
    game,
    ticket,
    auth,
    stripe,
    pagarme,
    coupon,
    paypal,
    coinPayments,
    mercadoPago,
    subscription,
    genericCheckout,
    faq,
    trial,
  },
});

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
