import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

import axiosInstance from '../configs/axios';

interface TrialProps {
  email: string;
  externalId: string;
  signature: string;
  expires_at: string;
  recaptcha: string;
}

export const trialWithOutCreditCard = createAsyncThunk('appTrial/trial3Days', async ({ email, externalId, signature, expires_at, recaptcha }: TrialProps) => {
  const body = { recaptcha };
  return axiosInstance
    .post(`/trials/3-days`, body, {
      params: {
        email,
        externalId,
        signature,
        expires_at,
      },
    })
    .then((res) => {
      return { status: res.status, data: res.data };
    })
    .catch((err: AxiosError) => {
      // @ts-ignore
      return { status: err.response?.status, data: err.response?.data?.message };
    });
});

export const appQuestionFaqSlice = createSlice({
  name: 'appTrial',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appQuestionFaqSlice.reducer;
