/* eslint-disable import/no-anonymous-default-export */
export default {
  // Ver quais sao as URL's certas para colocar os endpoints corretos
  meEndpoint: '/user/me',
  loginEndpoint: '/user/login',
  logoutEndpoint: '/user/logout',
  registerEndpoint: '/user',
  registerTrialEndpoint: '/user/trial',
  onTokenExpiration: 'logout',
  cookieName: '',
};
