import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

interface FaqProps {
  status: boolean;
  questionId: number;
}

export const saveQuestionFaq = createAsyncThunk('appQuestionFaq/SaveQuestionFaq', async ({ status, questionId }: FaqProps) => {
  const url = `/questions-votes/${questionId}/${status ? 'yes' : 'no'}`;

  try {
    const response = await axiosInstance.post(`${url}`);
    return { status: response.status, data: response.data?.data };
  } catch (err) {
    // @ts-ignore
    return { status: 500, error: err?.response?.data?.data };
  }
});

export const checkAnswer = createAsyncThunk('appQuestionFaq/CheckAnswer', async (questionId: number) => {
  try {
    const response = await axiosInstance.get(`/questions-votes/check-answer/${questionId}`);

    return { status: response?.status, data: response?.data?.data };
  } catch (err) {
    // @ts-ignore
    return { status: 500, error: err?.response?.data?.data };
  }
});

export const appQuestionFaqSlice = createSlice({
  name: 'appQuestionFaq',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appQuestionFaqSlice.reducer;
