import { createSlice } from '@reduxjs/toolkit';

export const appAuthSlice = createSlice({
  name: 'appGames',
  initialState: {
    isLoggedIn: false,
  },
  reducers: {},
});

export default appAuthSlice.reducer;
