import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '@/configs/axios';

interface MercadoPagoValidateProps {
  cc_token: string;
  plan_id: number;
  gateway_id: number;
  currency_id: number;
  coupon?: string;
  ref?: string;
  installments: number;
  user_doc?: string;
  user_phone?: string;
  credit_card?: string;
  payment_method: string;
  boleto: string;
  mp_id: string | undefined;
}
export const checkoutMercadoPago = createAsyncThunk('appCheckoutMercadoPago/checkoutMercadoPago', async (data: MercadoPagoValidateProps) => {
  try {
    const response = await axiosInstance.post('/checkout', data);
    return { status: response.status, data: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const checkoutMercadoPagoTrial = createAsyncThunk('appCheckoutMercadoPago/checkoutMercadoPagoTrial', async (data: MercadoPagoValidateProps) => {
  try {
    const response = await axiosInstance.post('/checkout/trial', data);
    return { status: response.status, data: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const appCheckoutMercadoPagoSlice = createSlice({
  name: 'appCheckoutMercadoPago',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appCheckoutMercadoPagoSlice.reducer;
