import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../../configs/axios';

interface PayPalCreditCardProps {
  holder_name: string;
  holder_doc: string;
  number: string;
  exp_month: string;
  exp_year: string;
  cvv: string;
}
export interface PayPalCheckoutProps {
  currency_id: number;
  plan_id: number;
  gateway_id: number;
  user_phone: string;
  user_doc: string;
  boleto: string;
  credit_card?: PayPalCreditCardProps | string;
  payment_method: 'paypal' | 'credit_card';
  coupon: string;
  ref: string;
  installments: number;
}

export const checkoutPayPal = createAsyncThunk('appCheckoutPayPal/checkoutPayPal', async (data: PayPalCheckoutProps) => {
  try {
    const response = await axiosInstance.post('/checkout', data);

    return { status: response.status, data: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const checkoutPayPalTrial = createAsyncThunk('appCheckoutPayPal/checkoutPayPalTrial', async (data: PayPalCheckoutProps) => {
  try {
    const response = await axiosInstance.post('/checkout/trial', data);

    return { status: response.status, data: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const appCheckoutPayPalSlice = createSlice({
  name: 'appCheckoutPayPal',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appCheckoutPayPalSlice.reducer;
