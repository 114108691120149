import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

export const getGames = createAsyncThunk('appGames/getGames', async () => {
  const response = await axiosInstance.get('/games');

  return response.data;
});

export const appGamesSlice = createSlice({
  name: 'appGames',
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGames.fulfilled, (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.data = action.payload.data;
    });
  },
});

export default appGamesSlice.reducer;
