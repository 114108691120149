import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

interface CheckoutStripe {
  gateway_id: number;
  plan_id: number;
  installments: number;
  currency_id: number;
  coupon?: string;
  ref?: string;
  cc_token: string;
  user_doc: string;
  user_phone: string;
  credit_card: string;
  payment_method: string;
  boleto: string;
}

export const checkoutStripe = createAsyncThunk('appCheckoutStripe/checkoutStripe', async (data: CheckoutStripe) => {
  try {
    const response = await axiosInstance.post('/checkout', data);

    return { status: response.status, data: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const checkoutStripeTrial = createAsyncThunk('appCheckoutStripe/checkoutStripeTrial', async (data: CheckoutStripe) => {
  try {
    const response = await axiosInstance.post('/checkout/trial', data);

    return { status: response.status, data: response.data };
  } catch (error) {
    // @ts-ignore
    return { success: false, error: error?.response?.data };
  }
});

export const appCheckoutStripeSlice = createSlice({
  name: 'appCheckoutStripe',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appCheckoutStripeSlice.reducer;
