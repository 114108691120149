import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

export interface AddressProps {
  country: string;
  region: string;
  region_code: string;
  postal_code: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
}

export interface CreditCardProps {
  holder_name: string;
  holder_doc: string;
  number: string;
  exp_month: string;
  exp_year: string;
  cvv: string;
}

export interface Payment {
  boleto?: AddressProps;
  credit_card?: CreditCardProps;
  user_doc?: string;
  user_phone: string;
  click_id?: string;
}

export interface CheckoutProps {
  gateway_id: number;
  plan_id: number;
  installments: number;
  currency_id: number;
  coupon?: string;
  ref?: string;
  cc_token?: string;
  payment_method: 'pix' | 'boleto' | 'credit_card';
  credit_card?: CreditCardProps | [];
  user_doc?: string;
  boleto?: AddressProps | [];
  user_phone: string;
  click_id?: string;
}

export const checkoutGeneric = createAsyncThunk('appGenericCheckout/checkoutGeneric', async (data: CheckoutProps) => {
  try {
    const response = await axiosInstance.post('/checkout', data);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { error: err?.response?.data };
  }
});

export const checkoutGenericTrial = createAsyncThunk('appGenericCheckout/checkoutGenericTrial', async (data: CheckoutProps) => {
  try {
    const response = await axiosInstance.post('/checkout/trial', data);

    return { status: response.status, data: response.data };
  } catch (err) {
    // @ts-ignore
    return { error: err?.response?.data };
  }
});

export const appGenericCheckoutSlice = createSlice({
  name: 'appGenericCheckout',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appGenericCheckoutSlice.reducer;
